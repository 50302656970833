<template>
  <div class="home">
    <v-layout row wrap class="">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> /
          <router-link to="/accounts">Accounts</router-link> /
          {{ name }}
        </h1>
      </v-flex>
      <v-flex xs12 md6>
        <v-layout row wrap>
          <v-flex xs12 class="pa-3">
            <h2>Reset Password</h2>
          </v-flex>
          <v-flex xs12 md12 lg12 class="pa-3">
            <p class="mb-1 uppercase primary--text">Password</p>
            <v-text-field
              label="Password"
              single-line
              outlined
              v-model="password"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 lg12 class="pa-3">
            <p class="mb-1 uppercase primary--text">Confirm Password</p>
            <v-text-field
              label="Confirm Password"
              single-line
              outlined
              v-model="confirm_password"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <v-btn
              large
              block
              v-if="!loading"
              class="primary black--text"
              @click="updateAccount"
              >Submit</v-btn
            >
            <v-btn large block v-else class="primary"
              ><v-progress-circular indeterminate></v-progress-circular
            ></v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { BASE_URL } from "@/config";
import Axios from "axios";
export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      name: "",
    };
  },
  mounted() {
    this.fetchAccount();
  },
  methods: {
    async fetchAccount() {
      let url = BASE_URL + "/user/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.imageSrc = data.display_picture;
      this.name = data.name;
    },
    async resetPassword() {
      if (this.password == this.confirmPassword) {
        let url = BASE_URL + "/user/reset-password" + this.$route.params.id;
        let payload = {
          password: this.password,
        };
        let { data } = await Axios.post(url, payload);
      } else {
      }
    },
  },
};
</script>